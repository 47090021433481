import styled from "styled-components";

import { SelectAddressDropdownStyledProps as Props } from "./SelectAddressDropdown.types";
import { DropdownOverlayStyledProps as OverlayProps } from "./SelectAddressDropdown.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile, desktop } = BREAKPOINTS;

const SelectAddressDropdownStyled = styled.div<Props>`
  display: ${props => (props.talkShop ? "none" : "flex")};
  align-items: center;
  height: 4.8rem;
  border-radius: 0.8rem;
  overflow: hidden;
  background-color: var(--pallette-black-s25-l2);

  .SelectAddressDropdown {
    &__dropdown {
      width: 100%;
      height: inherit;

      @media (max-width: ${desktop - 1}px) {
        display: none;
      }
    }

    &__button {
      background-color: var(--pallette-black-s25-l2);
      display: flex;
      width: 100%;
      height: inherit;
      padding: 1.2rem;
      font-size: 1.4rem;
      font-weight: 600;
      border: none;

      @media (max-width: ${mobile}px) {
        justify-content: center;
      }

      &-container {
        @media (min-width: ${desktop}px) {
          display: none;
        }

        @media (max-width: ${desktop}px) {
          width: 100%;
        }
      }

      &-text-container {
        max-width: 13.2rem;
      }

      &-text {
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--palette-white);
        font-size: 1.2rem;
        font-family: inherit;

        :first-child {
          font-size: 1.6rem;
          color: var(--palette-white);
          font-weight: 700;
        }

        &--extended {
          overflow: initial;
          padding-right: 0.8rem;
        }
      }

      & > svg {
        path {
          fill: var(--palette-white);
        }
      }
    }

    &__icon {
      width: 1.6rem;
      flex-shrink: 0;
    }
  }
`;

export const DropdownOverlayStyled = styled.div<OverlayProps>`
  position: fixed;
  display: grid;
  gap: 2rem;
  width: max-content;
  margin-top: 0.8rem;
  border: none;
  box-shadow: 0 0.4rem 0.4rem var(--palette-gray-s0-l20-a04),
    0 0.4rem 1.6rem var(--palette-gray-s0-l20-a08);
  border-radius: 1.2rem;
  background: var(--palette-black-s2-l17);
  padding: 2.4rem;
  padding-bottom: 3.2rem;

  @media (max-width: ${desktop}px) {
    bottom: 0;
    width: 100%;
    margin-top: 0;
    padding: 1.6rem;
    padding-top: 2.8rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .DropdownOverlay {
    &__close-icon {
      display: block;
      justify-self: end;
      cursor: pointer;
    }

    &__choose-workflow-desktop {
      margin-bottom: 1.2rem;
    }

    &__option {
      justify-content: flex-start;
      padding: 0;
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--palette-white);
      border: none;

      > svg {
        width: 2rem;
        height: 2rem;
        margin-right: 1.2rem;

        & path {
          fill: var(--palette-white);
        }
      }
    }

    &__divider {
      width: 100%;
      margin: 0;
      height: 0.8rem;
      background-color: var(--palette-black-s2-l21);
    }

    &__select-address {
      color: var(--palette-white);
    }
  }
`;

export default SelectAddressDropdownStyled;
